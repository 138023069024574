import { Helmet } from "react-helmet-async";
import { useState } from "react";
// @mui
import {
  Stack,
  Container,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Header from "./layout/dashboard/header";
import Nav from "./layout/dashboard/nav";






export const MarketingPage = ()=> {
 
  const [openNav, setOpenNav] = useState(false);


  const APP_BAR_MOBILE = 64;
  const APP_BAR_DESKTOP = 92;

  const StyledRoot = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
  });

  const Main = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <>
      <StyledRoot>
        <Header onOpenNav={() => setOpenNav(true)} />

        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main>
          <Helmet>
            <title> Marketing </title>
          </Helmet>

          <Container>
          <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
              Marketing
              </Typography>
            </Stack>
            <Stack
              direction="column"
              alignItems=""
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h5" gutterBottom>
              1. Advertisement
              </Typography>
              <Typography variant="h5" gutterBottom>
              2. Links
              </Typography>
              <Typography variant="h5" gutterBottom>
              3. emails of target Meal Hosts and Patrons by country
              </Typography>
              <Typography variant="h5" gutterBottom>
              4. Social Media
              </Typography>
            </Stack>
          
          </Container>
        </Main>
      </StyledRoot>
    </>
  );
}
