import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Tooltip,
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
// import Label from "../../components/label";
import Iconify from "./components/iconify";
// sections
import { UserListHead, UserListToolbar } from "./Patron/UserListHead";
// mock
// import USERLIST from '../_mock/user';
import { mainServerAppUrl } from "./../../apis/mainApi";
import Header from "./layout/dashboard/header";
import Nav from "./layout/dashboard/nav";
// import { confirmAlert } from 'react-confirm-alert';
// import 'react-confirm-alert/src/react-confirm-alert.css'
import moment from "moment/moment";
import { toast } from "react-hot-toast";
import { getAuthUser } from "../Authentication/authHelper";
import { useParams } from "react-router-dom";

const TABLE_HEAD = [
  { id: "srno", label: "Sr. No.", alignRight: false },
  { id: "Date", label: "Date", alignRight: false },
  { id: "id", label: "Membership No.", alignRight: false },
  { id: "Role", label: "Role", alignRight: false },
  { id: "Name", label: "Name", alignRight: false },
  { id: "Email", label: "Email", alignRight: false },
  { id: "Phone", label: "Phone", alignRight: false },
  { id: "Messages", label: "Messages", alignRight: false },
  { id: "Replies", label: "Replies", alignRight: false },
];

// ----------------------------------------------------------------------
const USERLIST = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export const CommunicationPage = () => {
  // const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState("name");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [messages, setMessages] = useState([]);
  const [openNav, setOpenNav] = useState(false);
  const [openMessagePopup, setOpenMessagePopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const [showReplyFields, setShowReplyFields] = useState(false);
  const [buttonDisable, setButtonDisable] = useState();
  const [action, setAction] = useState("");

  const replyTextRef = useRef("");

  const params = useParams();

  const handleOpenMessagePopup = (message, email, userName, _id, reply) => {
    setSelectedMessage(message);
    setSelectedEmail(email);
    setSelectedUserName(userName);
    setSelectedId(_id);
    setShowReplyFields(true);

    if (reply) {
      setButtonDisable(true);
    } else {
      setButtonDisable(false);
    }
    setOpenMessagePopup(true);
  };

  const handleOpenPopup = (msg, email, action) => {
    setOpenPopup(true);
    setSelectedMessage(msg);
    setSelectedEmail(email);
    setAction(action);
  };

  const handleCloseMessagePopup = () => {
    setOpenMessagePopup(false);
    setShowReplyFields(false);
    setOpenPopup(false);
  };

  const handleReplyButtonClick = () => {
    setShowReplyFields(true);
  };

  useEffect(() => {
    axios
      .get(mainServerAppUrl + `/admin/communications/${params.user}`)
      .then((result) => {
        // console.log(result.data.allMessages);
        setMessages(result.data.allMessages);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [params.user]);

  const handleReplySend = (e) => {
    e.preventDefault();
    const data = {
      reply: replyTextRef.current,
      adminEmail: getAuthUser().email,
      userName: selectedUserName,
      _id: selectedId,
      userEmail: selectedEmail,
    };

    axios
      .post(mainServerAppUrl + "/admin/communications/reply", data)
      .then((response) => {
        toast.success("Message Sent!", {
          duration: 3000,
          position: "top-center",
        });
        replyTextRef.current = null;

        // Fetch the updated data again after a successful response
        axios
          .get(mainServerAppUrl + "/admin/communications")
          .then((result) => {
            setMessages(result.data.allMessages);
            handleCloseMessagePopup(); // Close the dialog after fetching the updated data
          })
          .catch((error) => {
            console.log(error.response);
          });
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        toast.error("Message not sent. Please try again.", {
          duration: 3000,
          position: "top-center",
        });
      });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - messages.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;

  const APP_BAR_MOBILE = 64;
  const APP_BAR_DESKTOP = 92;

  const StyledRoot = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
  });

  const Main = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  return (
    <>
      <StyledRoot>
        <Header onOpenNav={() => setOpenNav(true)} />

        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main>
          <Helmet>
            <title> Emails </title>
          </Helmet>

          <Container sx={{ minWidth: "100%" }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                Emails
              </Typography>
            </Stack>

            <Card>
              <>
                {" "}
                {openMessagePopup && (
                  <Dialog
                    open={openMessagePopup}
                    onClose={handleCloseMessagePopup}
                    aria-labelledby="message-dialog-title"
                    fullWidth
                    maxWidth="xs"
                  >
                    <DialogTitle id="message-dialog-title">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseMessagePopup}
                        aria-label="close"
                        sx={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                      >
                        <Tooltip title="Close">
                          <Iconify icon={"eva:close-fill"} sx={{ mr: 2 }} />
                        </Tooltip>
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      <Stack>
                        <form onSubmit={handleReplySend}>
                          <Typography>
                            From: <b>{selectedEmail}</b>
                            {/* getAuthUser().email */}
                          </Typography>
                          <br />
                          <Typography>Message: {selectedMessage}</Typography>
                          <hr />
                          {/* <DialogTitle id="message-dialog-title"> */}
                          {/* <Typography>Reply  </Typography> */}

                          {/* </DialogTitle> */}

                          <Typography>
                            From: <b>{getAuthUser().email}</b>
                          </Typography>
                          <Typography>
                            To: <b>{selectedEmail}</b>
                          </Typography>

                          <TextField
                            placeholder="Write your reply here"
                            multiline
                            rows={6}
                            sx={{
                              width: "100%", // Set the width to 100% using custom style
                            }}
                            // value={replyText}
                            onChange={(e) =>
                              (replyTextRef.current = e.target.value)
                            }
                            required
                          />
                          <br />
                          <Button
                            type="submit"
                            sx={{ color: "black", border: "1px solid black" }}
                            disabled={buttonDisable}
                          >
                            <Iconify icon={"mdi:reply"} sx={{ mr: 1 }} />
                            Send
                          </Button>
                        </form>
                      </Stack>
                    </DialogContent>
                  </Dialog>
                )}
                {openPopup && (
                  <Dialog
                    open={openPopup}
                    onClose={handleCloseMessagePopup}
                    aria-labelledby="message-dialog-title"
                    fullWidth
                    maxWidth="xs"
                  >
                    <DialogTitle id="message-dialog-title">
                      <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleCloseMessagePopup}
                        aria-label="close"
                        sx={{
                          position: "absolute",
                          top: "8px",
                          right: "8px",
                        }}
                      >
                        <Tooltip title="Close">
                          <Iconify icon={"eva:close-fill"} sx={{ mr: 2 }} />
                        </Tooltip>
                      </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                      {action === "message" ? (
                        <>
                          <Typography><b>From : </b> <i>{selectedEmail}</i></Typography><hr/>
                          <Typography><b>Message : </b> <i>{selectedMessage}</i></Typography>
                        </>
                      ) : (
                        <>
                          <Typography><b>Replied by : </b> <i>{selectedEmail}</i></Typography><hr/>
                          <Typography><b>Reply : </b> <i>{selectedMessage}</i></Typography>
                        </>
                      )}
                    </DialogContent>
                  </Dialog>
                )}
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={messages.length}
                      numSelected={selected.length}
                      onRequestSort={handleRequestSort}
                      onSelectAllClick={handleSelectAllClick}
                    />
                    <TableBody>
                      {messages
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((msg, ind) => (
                          <TableRow key={ind}>
                            <TableCell align="left">
                            {ind + 1}
                          </TableCell>
                            <TableCell align="left">
                              {moment(msg.createdAt).format(
                                "DD/MM/YYYY hh:mm:ss"
                              )}
                            </TableCell>
                            {msg.membership_no ? (
                              <TableCell align="left">
                                {msg.membership_no}
                              </TableCell>
                            ) : (
                              <TableCell align="right">
                                <MenuItem>
                                  <Iconify
                                    icon={"carbon:not-available"}
                                    sx={{ mr: 2 }}
                                  />
                                </MenuItem>
                              </TableCell>
                            )}
                            {msg.role === "MealHost" ? (
                              <TableCell
                                align="left"
                                style={{
                                  color: "#388e3c",
                                }}
                              >
                                {msg.role}
                              </TableCell>
                            ) : msg.role === "Patron" ? (
                              <TableCell
                                align="left"
                                style={{
                                  color: "#f57c00",
                                }}
                              >
                                {msg.role}
                              </TableCell>
                            ) : (
                              <TableCell
                                align="left"
                                style={{
                                  color: "#0288d1",
                                }}
                              >
                                {msg.role}
                              </TableCell>
                            )}

                            <TableCell align="left">{msg.userName}</TableCell>
                            <TableCell align="left">{msg.userEmail}</TableCell>
                            <TableCell align="left">{msg.phone}</TableCell>

                            <TableCell align="right">
                              {/* {msg.message} */}
                              <MenuItem
                                sx={{ color: "Brown" }}
                                onClick={() =>
                                  handleOpenPopup(
                                    msg.message,
                                    msg.userEmail,
                                    "message"
                                  )
                                }
                              >
                                <Tooltip title={msg.message}>
                                  <Iconify
                                    icon={"mdi:message-reply-text-outline"}
                                    sx={{ mr: 2 }}
                                  />
                                </Tooltip>
                              </MenuItem>
                            </TableCell>
                            <TableCell align="right">
                              {msg.reply ? (
                                <MenuItem
                                  onClick={() =>
                                    handleOpenPopup(
                                      msg.reply,
                                      msg.adminEmail,
                                      "reply"
                                    )
                                  }
                                >
                                  <Tooltip title={msg.reply}>
                                    <Iconify
                                      icon={"mdi:message-reply-text"}
                                      sx={{ mr: 2 }}
                                    />
                                  </Tooltip>
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  onClick={() =>
                                    handleOpenMessagePopup(
                                      msg.message,
                                      msg.userEmail,
                                      msg.userName,
                                      msg._id,
                                      msg.reply
                                    )
                                  }
                                >
                                  <Tooltip title="Click To Reply">
                                    <Iconify
                                      icon={"mdi:reply-outline"}
                                      sx={{ mr: 2 }}
                                    />
                                  </Tooltip>
                                </MenuItem>
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>

                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: "center",
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>
                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete
                                words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={messages.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    ".MuiTablePagination-displayedRows": {
                      marginBottom: "0px",
                    },
                    ".MuiTablePagination-selectLabel": {
                      marginBottom: "2px",
                    },
                    ".MuiTablePagination-select": {
                      marginBottom: "-1px",
                    },
                  }}
                />
              </>
            </Card>
          </Container>
        </Main>
      </StyledRoot>
    </>
  );
};
