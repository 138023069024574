import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useEffect, useState } from "react";
import axios from "axios";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
// sections
import { UserListHead, UserListToolbar } from "../Patron/UserListHead";
// mock
// import USERLIST from '../_mock/user';
import { toast } from "react-hot-toast";
import { mainServerAppUrl } from "../../../apis/mainApi";
import Header from "../layout/dashboard/header";
import Nav from "../layout/dashboard/nav";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'

import UserDetails from "./userDetails";
// import OrdersByPatron from "../Patron/ordersByPatron";
import OrdersByMealhost from "./ordersByMealhost";
import MealDetails from "./mealdetails";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "srno", label: "Sr. No.", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "number", label: "Membership No.", alignRight: false },
  { id: "phone", label: "Phone", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  { id: "status", label: "Subscription Status", alignRight: false },
  { id: "subscription", label: "Subscription", alignRight: false },
  { id: "details", label: "More Details", alignRight: false },
  { id: "meals", label: "Meals", alignRight: false },
  { id: "orders", label: "Orders", alignRight: false },
  // { id: "remove", label: "Delete User", alignRight: false },
];

// ----------------------------------------------------------------------
const USERLIST = [];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function MealhostPage() {
  // const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [mealhosts, setMealhosts] = useState([]);
  const [orders,setOrders] = useState([]);
  const [meals,setMeals] = useState([]);

  const [openNav, setOpenNav] = useState(false);

  const [showUserDetails, setShowUserDetails] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [showMealDetails, setShowMealDetails] = useState(false)

  useEffect(() => {
    axios
      .get(mainServerAppUrl + "/admin/mealhosts")
      .then((result) => {
        // console.log(result.data);
        setMealhosts(result.data.allMealHosts);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);


  // const handleDelete = (membershipNB, ind) => {
  //   confirmAlert({
  //     message: "Do you want to DELETE this User?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {
  //           axios
  //             .delete(mainServerAppUrl + `/admin/${membershipNB}`)
  //             .then((result) => {
  //               // console.log(result.data);
  //               toast.success(result.data.message, {
  //                 id: "deleted",
  //                 duration: 3000,
  //               });

  //               // mealhosts.splice(ind, 1);
  //               const updatedMealhosts = mealhosts.filter((mealhost) => mealhost.membership_no !== membershipNB);
  //               setMealhosts(updatedMealhosts);
  //             })
  //             .catch((error) => {
  //               console.log(error.response);
  //               toast.error(error.response.data.message, {
  //                 id: "cancel",
  //                 duration: 3000,
  //               });
  //             });
  //         },
  //       },
  //       {
  //         label: "No",
  //         // onClick: () => alert('Click No')
  //       },
  //     ],
  //   });
  // };


  const handleOpenOrder = (mealhostId)=>{
    // console.log(patronId);
    axios
    .get(mainServerAppUrl + `/admin/mealhosts/${mealhostId}`)
    .then((result) => {
      // console.log("result",result.data.allOrders);
      setOrders(result.data.allOrders);
    setShowOrderDetails(true);
    })
    .catch((error) => {
      console.log(error.response);
      toast.error(error.response)
    });
      }
      
    const handleOpenMeals = (id)=>{
    // console.log(patronId);
    axios
    .get(mainServerAppUrl + `/admin/meals/${id}`)
    .then((result) => {
      // console.log("result",result.data);
      setMeals(result.data.allMeals);
      setShowMealDetails(true);
    })
    .catch((error) => {
      console.log(error.response);
      toast.error(error.response)
    });
      }
      

  const handleSubscription = (mealhostID) => {
    confirmAlert({
      message: "Do you want to CANCEL this subscription?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            axios
              .delete(mainServerAppUrl + `/v1/subscription/${mealhostID}`)
              .then((result) => {
                console.log(result.data);
                toast.success(result.data.message, {
                  id: "subscription",
                  duration: 3000,
                });
              })
              .catch((error) => {
                console.log(error.response);
                toast.error(error.response.data.message, {
                  id: "error",
                  duration: 3000,
                });
              });
          },
        },
        {
          label: "No",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  const handleOpenMenu = (user) => {
    // setOpen(event.currentTarget);
    setSelectedUser(user);
    setShowUserDetails(true);
    // console.log(event);
    // console.log(user)
  };

  // const handleCloseMenu = () => {
  //   setOpen(null);
  // };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleCloseUserDetails = () => {
    setShowUserDetails(false);
  };

  const handleCloseOrderDetails = ()=>{
    setShowOrderDetails(false);
  }

  const handleCloseMealDetails = ()=>{
    setShowMealDetails(false);
  }


  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - mealhosts.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;

  const APP_BAR_MOBILE = 64;
  const APP_BAR_DESKTOP = 92;

  const StyledRoot = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
  });

  const Main = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  }));

  return (
    <>
      <StyledRoot>
        <Header onOpenNav={() => setOpenNav(true)} />

        <Nav openNav={openNav} onCloseNav={() => setOpenNav(false)} />

        <Main>
          <Helmet>
            <title> Meal Hosts </title>
          </Helmet>

          <Container sx={{minWidth:"100%"}}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={5}
            >
              <Typography variant="h4" gutterBottom>
                Meal Hosts
              </Typography>
            </Stack>

            <Card>
              {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
              {showUserDetails ? (
  <UserDetails
    user={selectedUser}
    // handleDelete={handleDelete}
    onClose={handleCloseUserDetails}
  />
):showOrderDetails?(
  <OrdersByMealhost onClose={handleCloseOrderDetails} orders={orders}/>
):showMealDetails?(
  <MealDetails onClose={handleCloseMealDetails} mealDetails={meals} setMeals={setMeals}/>
):<>
              <TableContainer sx={{ minWidth: 800 }}>
                
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={mealhosts.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {mealhosts
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((mealhost, ind) => (
                        <TableRow key={ind}>
                          <TableCell align="left">
                            {ind + 1}
                          </TableCell>
                          <TableCell align="left">
                            {mealhost.first_name + " " + mealhost.last_name}
                          </TableCell>
                          <TableCell align="left">
                            {mealhost.membership_no}
                          </TableCell>
                          <TableCell align="left">{mealhost.phone}</TableCell>
                          <TableCell align="left">{mealhost.email}</TableCell>
                          {mealhost.membership_status === "active" ? (
                            <TableCell
                              align="left"
                              style={{
                                color: "green",
                                textTransform: "uppercase",
                              }}
                            >
                              {mealhost.membership_status}
                            </TableCell>
                          ) : (
                            <TableCell
                              align="left"
                              style={{
                                color: "red",
                                textTransform: "uppercase",
                              }}
                            >
                              {mealhost.membership_status}
                            </TableCell>
                          )}
                          {mealhost.membership_status === "active" ? (
                            <TableCell align="right">
                              <MenuItem
                                sx={{ color: "error.main" }}
                                onClick={() =>
                                  handleSubscription(mealhost._id, ind)
                                }
                              ><Tooltip title="Cancel">
                                <Iconify icon={"mdi:close"} sx={{ mr: 2 }} />
                                </Tooltip>
                              </MenuItem>
                            </TableCell>
                          ) : (
                            <TableCell align="right">
                              <MenuItem>
                                <Iconify
                                  icon={"carbon:not-available"}
                                  sx={{ mr: 2 }}
                                />
                              </MenuItem>
                            </TableCell>
                          )}
                          
                          <TableCell align="right">
                            <MenuItem
                              sx={{ color: "black" }}
                              onClick={() => handleOpenMenu( mealhost)}
                            ><Tooltip title="View Details">
                              <Iconify
                                icon={"eva:arrow-forward-fill"}
                                sx={{ mr: 2 }}
                              />
                                </Tooltip>
                            </MenuItem>
                          </TableCell>
                          <TableCell align="right">
                            <MenuItem
                              sx={{ color: "black" }}
                              onClick={() => handleOpenMeals(mealhost._id)}
                            ><Tooltip title="View Meals">
                              <Iconify
                                icon={"mdi:food-outline"}
                                sx={{ mr: 2 }}
                              />
                                </Tooltip>
                            </MenuItem>
                          </TableCell>
                          <TableCell align="right">
                            <MenuItem
                              sx={{ color: "black" }}
                              onClick={() => handleOpenOrder(mealhost.membership_no
                                )}
                            ><Tooltip title="View All Orders">
                              <Iconify
                                icon={"eva:shopping-bag-outline"}
                                sx={{ mr: 2 }}
                              />
                                </Tooltip>
                            </MenuItem>
                          </TableCell>
                          {/* <TableCell align="right">
                            <MenuItem
                              sx={{ color: "error.main" }}
                              onClick={() =>
                                handleDelete(mealhost.membership_no, ind)
                              }
                            ><Tooltip title="Delete">
                              <Iconify
                                icon={"eva:trash-2-outline"}
                                sx={{ mr: 2 }}
                              />
                                </Tooltip>
                            </MenuItem>
                          </TableCell> */}
                        </TableRow>
                      ))}
                  </TableBody>

                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <Paper
                            sx={{
                              textAlign: "center",
                            }}
                          >
                            <Typography variant="h6" paragraph>
                              Not found
                            </Typography>

                            <Typography variant="body2">
                              No results found for &nbsp;
                              <strong>&quot;{filterName}&quot;</strong>.
                              <br /> Try checking for typos or using complete
                              words.
                            </Typography>
                          </Paper>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}

                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={mealhosts.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  ".MuiTablePagination-displayedRows": {
                    marginBottom: "0px",
                  },
                  ".MuiTablePagination-selectLabel": {
                    marginBottom: "2px",
                  },
                  ".MuiTablePagination-select": {
                    marginBottom: "-1px",
                  },
                }}
              />
              </>
              }
            </Card>
          </Container>
        </Main>
      </StyledRoot>
    </>
  );
}
