import React, { useEffect, useState } from "react";
import axios from "axios";
import { mainServerAppUrl } from "../../apis/mainApi";
import moment from "moment";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";
import { useLocation } from "react-router-dom";
// import { SideMenu } from "../Side-Menu/sideMenu";

export const MealHostContact = () => {
  const [mealHostContact, setMealHostContact] = useState([]);

  const location = useLocation();

  const getMealHostDetails = () => {
    axios
      .post(mainServerAppUrl + "/user/patron/meal-host-info",{membership_no:location.state})
      .then((result) => {
        setMealHostContact(result?.data?.mealhost);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMealHostDetails();
  }, [location.state]);  

  return (
    <div className="max-resolution">
      <div className="home-section-1 position-relative">
          <span className="text_msg">
            PATRON CONGRATULATIONS YOUR MEAL HOST INFORMATION
          </span>
        </div>
     <Header/>
      <main>
        <div className="home-section-1 position-relative home-section-2">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
          <a
            href="#"
            className="text_msg"
            style={{ fontWeight: "500", fontSize: "17px" }}
          >
            <i className="fas fa-map-marker-alt" style={{ color: "red" }}>
              {" "}
            </i>{" "}
            Click for Directions to your Meal Host{" "}
          </a>
        </div>
        <div className="custom-form centered pb-4">
          <form>
            <div className="row">
              <div className="col-12">
                <input
                  type="date"
                  name="date"
                  placeholder="Date"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  className="w-100 form-control pt-0"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  value={mealHostContact?.first_name}
                  className="w-100 form-control"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={mealHostContact?.last_name}
                  className="w-100 form-control"
                  readOnly
                />
              </div>

              <div className="col-12">
                <input
                  type="text"
                  name="street"
                  placeholder="Street"
                  value={
                    mealHostContact?.address_line1 +
                    "," +
                    mealHostContact?.address_line2
                  }
                  className="w-100 form-control"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="city"
                  placeholder="City"
                  value={mealHostContact?.city}
                  className="w-100 form-control"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="state"
                  placeholder="State"
                  value={mealHostContact?.state}
                  className="w-100 form-control"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="country"
                  placeholder="Country"
                  value={mealHostContact?.country}
                  className="w-100 form-control"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="number"
                  placeholder="Zip Code"
                  value={mealHostContact?.zip_code}
                  className="w-100 form-control"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  placeholder="Mobile"
                  value={
                    mealHostContact?.country_code +
                    "-" +
                    mealHostContact?.phone
                  }
                  className=" form-control"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="email"
                  placeholder="Email"
                  value={mealHostContact?.email}
                  className="w-100 form-control"
                  readOnly
                />
              </div>
            </div>
          </form>
        </div>
      </main>
      <Footer/>
    </div>
  );
};
