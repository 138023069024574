import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SideMenu } from "../Side-Menu/sideMenu";

export const PatronOrderDetails = () => {
  // const [orderConfirmed, setOrderConfirmed] = useState("");

  const navigate = useNavigate();
  // const { state } = useLocation();
  // let checkoutUrl = "";
  // let order = {};
  // if (state) {
  //   checkoutUrl = state.checkoutUrl;
  //   order = state.order;
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-resolution">
      <header>
        <div className="header-info">
          <div className="left-part">
            <a href="/patron-home" className="site-logo">
              <img src={require("../../assets/images/bannerLogo.png")} />
            </a>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to
              enjoy unique cuisines, healthy regional foods by many who enjoy
              cooking and eager to serve variety of foods.{" "}
            </p>
            <p>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>
      <main>
        <div className="home-section-1 position-relative">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
          <span className="text_msg">PATRON- YOUR ORDER IS PROCESSING</span>
        </div>
        <div className="custom-form centered pb-4" style={{ paddingTop: '3rem' }}>
          <form>
            <div style={{ display: "flex", justifyContent: "center", textAlign: "center", fontSize: "25px", minHeight: "140px" }}>
              <p>Your order is in processing.<br /> After your order has been accepted, the amount will be auto debited from your account.</p>
            </div>
            {/* <div className="row">
              <div className="col-12">
                <input
                  type="text"
                  name="meal"
                  value={"Meal - " + order.meal_name}
                  className="w-100 form-control pt-0"
                  readOnly={true}
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="type"
                  value={"Meal Type - " + order.meal_type}
                  className="w-100 form-control"
                  readOnly={true}
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="status"
                  value={"Order Status - " + order.order_status}
                  className="w-100 form-control"
                  readOnly={true}
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="qty"
                  value={"Order Quantity - " + order.order_quantity}
                  className="w-100 form-control"
                  readOnly={true}
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name=""
                  value={"Payment Status - " + order.payment_status}
                  className="w-100 form-control "
                  readOnly={true}
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name=""
                  value={"Total - " + order.order_quantity * order.price + "$"}
                  className="w-100 form-control "
                  readOnly={true}
                />
              </div>
            </div> */}
            <div className="row">
              <div className="col-12 p-2" style={{ marginTop: '5rem' }}>
                <button className="btn w-100" type="button" onClick={() => navigate("/patron-home")}>
                  GO TO HOME
                </button>
              </div>

            </div>
          </form>
        </div>
      </main>
      <footer>
        <div className="footer-content">
          <div className="disclaimer">
            <p className="pb-2">DISCLAIMER</p>
          </div>
          <p>
          Homey-FOODS is a service that brings Meal
            Hosts and Patrons together. Homey-FOODS makes no representations,
            endorsements. or warranties of any kind whatsoever of Meal Hosts and
            Customers. Both parties agree to comply to local government laws, be
            of legal age and Homey-FOODS shall not be held responsible from disputes,
            damages and controversies.
          </p>
        </div>
        <div className="footer_bottom">
          <a href="https://www.homey-foods.com" className="site_link">
            www.homey-foods.com
          </a>
          <div className="social-media">
            <ul>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin"></i>{" "}
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <p>© 2023 Homey-FOODS</p>
        </div>
      </footer>
    </div>
  );
};
