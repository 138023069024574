import React, { useEffect, useState } from "react";
import axios from "axios";
import { mainServerAppUrl } from "../../apis/mainApi";
import moment from "moment";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";
// import { SideMenu } from "../Side-Menu/sideMenu";
import { Rating } from "react-simple-star-rating";
import { Typography } from "@mui/material";
import { getAuthUser } from "../Authentication/authHelper";

export const MealHostToday = () => {
  const [mealHost, setMealhost] = useState([]);
  const [noOrders, setNoOrders] = useState();

  const data = {
    patron_id: getAuthUser()?.membership_no,
  };

  const getMealHostDetails = () => {
    axios
      .post(mainServerAppUrl + "/user/patron/mealhost-today", data)
      .then((result) => {
        console.log(result);
        setMealhost(result?.data);
        setNoOrders(false)
      })
      .catch((error) => {
        console.log(error);
        setNoOrders(true)
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getMealHostDetails();
  }, []);

  return (
    <div className="max-resolution">
      <div className="home-section-1 position-relative">
        <span className="text_msg">PATRON YOUR MEALHOST TODAY</span>
      </div>
      <Header />
      <main>
        <div className="home-section-1 position-relative home-section-2">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        {noOrders ? (
              <div
                className="meal_history_section centered pb-4"
                style={{ textAlign: "center" }}
              >
                <Typography variant="h6">You dont have any orders for today</Typography>
              </div>
            ) : (
        <div className="custom-form centered pb-4">
          <div className="meal_history_section centered pb-4">
            {mealHost &&
              mealHost.map((mealHostContact) => (
                <div className="meal_history_box">
                  <a
                    href="#"
                    className="text_msg"
                    style={{ fontWeight: "500", fontSize: "17px" }}
                  >
                    <i
                      className="fas fa-map-marker-alt"
                      style={{ color: "red" }}
                    >
                      {" "}
                    </i>{" "}
                    Click for Directions to your Meal Host{" "}
                  </a>
                  <hr />
                  <div className="row">
                    <div className="col-12">
                      <div className="patrons-details">
                        <Typography variant="h6">Order details:</Typography>
                        <ul className="list-unstyled">
                          <Typography>
                            {moment(mealHostContact?.ordered_On).format(
                              "DD-MM-YYYY"
                            )}
                          </Typography>

                          <Typography>
                            {mealHostContact?.meal_name +
                              " (" +
                              mealHostContact?.meal_type +
                              ")"}
                          </Typography>
                          <Typography>
                            {"Qty : " + mealHostContact?.order_quantity}
                          </Typography>
                        </ul>
                      </div>

                      <div className="patrons-details">
                        <Typography variant="h6">Mealhost details:</Typography>
                        <ul className="list-unstyled">
                          <Typography>
                            {mealHostContact?.first_name +
                              " " +
                              mealHostContact?.last_name}
                          </Typography>
                          <Typography>
                            {mealHostContact?.address_line1 +
                              "," +
                              mealHostContact?.address_line2 +
                              "," +
                              mealHostContact?.city +
                              "," +
                              mealHostContact?.state +
                              "-" +
                              mealHostContact?.zip_code}
                          </Typography>
                          <Typography>
                            {"+" +
                              mealHostContact?.country_code +
                              "-" +
                              mealHostContact?.phone}
                          </Typography>
                          <Typography>{mealHostContact?.email}</Typography>
                          <Rating
                            initialValue={mealHostContact?.ratings}
                            readonly
                            allowFraction
                            size={23}
                            title={mealHostContact?.ratings}
                          />{" "}
                          <span style={{ fontSize: "15px" }}>
                            ( {mealHostContact?.num_of_reviews})
                          </span>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>)}
      </main>
      <Footer />
    </div>
  );
};
