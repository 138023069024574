export const cardNumberMask = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  export const accountNumberMask = [
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    " ",
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
    /[0-9]/,
  ];

  export const routingNumberMask = [
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  " ",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  " ",
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  /[0-9a-zA-Z]/,
  ];