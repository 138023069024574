import axios from "axios";
import { useEffect, useState } from "react";
import { mainServerAppUrl } from "../../apis/mainApi";
import { getAuthUser } from "../Authentication/authHelper";

import { Helmet } from "react-helmet-async";
// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

// sections
import AppWidgetSummary from "./AppWidgetSummary";
import Header from "./layout/dashboard/header";
import Nav from "./layout/dashboard/nav";
import Chart from 'react-apexcharts'

export default function DashboardAppPage() {
  const theme = useTheme();
  const [allCount, setAllCount] = useState({});
  const [open, setOpen] = useState(false);
  const [mealhostByCountry, setMealhostByCountry] = useState();
  const [patronByCountry, setPatronByCountry] = useState();
  const [mealChart, setMealChart] = useState();
  const [membershipChart,setMembershipChart] = useState();
  const [commissionChart, setCommissionChart] = useState();


  const APP_BAR_MOBILE = 64;
  const APP_BAR_DESKTOP = 92;

  const StyledRoot = styled("div")({
    display: "flex",
    minHeight: "100%",
    overflow: "hidden",
  });

  const Main = styled("div")(({ theme }) => ({
    flexGrow: 1,
    overflow: "auto",
    minHeight: "100%",
    paddingTop: APP_BAR_MOBILE + 24,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.up("lg")]: {
      paddingTop: APP_BAR_DESKTOP + 24,
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  // dashboard counts...............................................................................

  useEffect(() => {
    axios
      .get(mainServerAppUrl + "/admin/count")
      .then((result) => {
        // console.log(result.data);
        setAllCount(result.data.allCounts);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);


  // Mealhost by country...............................................................................

  // useEffect(() => {
  //   axios
  //     .get(mainServerAppUrl + "/admin/mealhost/country")
  //     .then((result) => {
  //       console.log("mealhostchart",result.data.mealhostByCountry);
  //       const formattedResult = result.data.mealhostByCountry.reduce((acc, { count, country }) => {
  //         acc[country] = count;
  //         return acc;
  //       }, {});
  //       setMealhostByCountry(formattedResult);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // }, []);
  useEffect(() => {
    axios
      .get(mainServerAppUrl + "/admin/mealhost/country")
      .then((result) => {
        // console.log("mealhostchart",result.data.mealhostByCountry);
        setMealhostByCountry(result.data.mealhostByCountry);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);


  // Patron by country...............................................................................

  // useEffect(() => {
  //   axios
  //     .get(mainServerAppUrl + "/admin/patron/country")
  //     .then((result) => {
  //       // console.log(result.data.patronByCountry);
  //       const formattedResult = result.data.patronByCountry.reduce((acc, { count, country }) => {
  //         acc[country] = count;
  //         return acc;
  //       }, {});
  //       setPatronByCountry(formattedResult);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // }, []);
  useEffect(() => {
    axios
      .get(mainServerAppUrl + "/admin/patron/country")
      .then((result) => {
        // console.log(result.data.patronByCountry);
        setPatronByCountry(result.data.patronByCountry);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);


  // meals offered...............................................................................

  // useEffect(() => {
  //   axios
  //     .get(mainServerAppUrl + "/admin/mealhost/meals")
  //     .then((result) => {
  //       // console.log(result.data);
  //       const formattedResult = result.data.meals.reduce((acc, { _id, count }) => {
  //         acc[_id] = count;
  //         return acc;
  //       }, {});
  //       // console.log(formattedResult)
  //       setMealChart(formattedResult);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // }, []);
  useEffect(() => {
    axios
      .get(mainServerAppUrl + "/admin/mealhost/meals")
      .then((result) => {
        console.log(result.data);
        setMealChart(result.data.meals);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);


  useEffect(() => {
    axios
      .get(mainServerAppUrl + "/admin/mealhost/membership")
      .then((result) => {
        // console.log(result.data);
        const chartData = result.data.memberships.map(({ _id, totalFees }) => ({
          x: _id,
          y: totalFees
        }));
        console.log("membershipchart",chartData)
        setMembershipChart(chartData);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);

  useEffect(() => {
    axios
      .get(mainServerAppUrl + "/admin/mealhost/commission")
      .then((result) => {
        console.log(result.data);
        const chartData = result.data.commission.map(({ _id, commission }) => ({
          x: _id,
          y: commission
        }));
        // console.log(chartData)
        setCommissionChart(chartData);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, []);



  // const mealhostOptions = {
  //   chart: {
  //     type: 'donut',
  //   },
  //   labels: mealhostByCountry && Object.keys(mealhostByCountry),
  //   legend: {
  //     position: 'bottom',
  //   },
  //     colors: ['#D6A7A7', '#D9D3A7', '#A7D9B7', '#A7C5D9', '#C4A7D9','#FEC8D8'],
  // };
  const sortedMealhostByCountry = mealhostByCountry && mealhostByCountry.sort((a, b) => b.count - a.count);
  const mealhostOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false // Set to false to hide the default side menu
      }
    },
    colors: ['#007500'],
    series: [
      {
        name: 'Mealhost By Country',
        data: sortedMealhostByCountry && sortedMealhostByCountry.map(item => item.count)
      }
    ],
    xaxis: {
      categories:sortedMealhostByCountry && sortedMealhostByCountry.map(item => item.country),
      labels: {
        style: {
          fontSize: '12px',
        }
      }
    },
    yaxis: {
      categories:sortedMealhostByCountry && sortedMealhostByCountry.map(item => item.count),
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    }
  };



  // const patronOptions = {
  //   chart: {
  //     type: 'donut',
  //   },
  //   labels: patronByCountry && Object.keys(patronByCountry),
  //   legend: {
  //     position: 'bottom',
  //   },
  //     colors: ['#D6A7A7', '#D9D3A7', '#A7D9B7', '#A7C5D9', '#C4A7D9','#FEC8D8'],
  // };
  const sortedPatronByCountry = patronByCountry && patronByCountry.sort((a, b) => b.count - a.count);
  const patronOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false // Set to false to hide the default side menu
      }
    },
    colors: ['#E97451'],
    series: [
      {
        name: 'Patrons By Country',
        data: sortedPatronByCountry && sortedPatronByCountry.map(item => item.count)
      }
    ],
    xaxis: {
      categories:sortedPatronByCountry && sortedPatronByCountry.map(item => item.country),
      labels: {
        style: {
          fontSize: '12px',
        }
      }
    },
    yaxis: {
      categories:sortedPatronByCountry && sortedPatronByCountry.map(item => item.count),
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    }
  };


  const sortedMeals = mealChart && mealChart.sort((a, b) => b.count - a.count);
  // const mealOptions = {
  //   chart: {
  //     type: 'pie',
  //   },
  //   labels: mealChart && Object.keys(mealChart),
  //   legend: {
  //     position: 'bottom',
  //   },
  //     colors: ['#D9D3A7', '#A7D9B7', '#A7C5D9', '#C4A7D9','#FEC8D8'],
  // };
  const mealOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false // Set to false to hide the default side menu
      }
    },
    colors: ['#20ADF7'],
    series: [
      {
        name: 'Types of Meals',
        data: sortedMeals && sortedMeals.map(item => item.count)
      }
    ],
    xaxis: {
      categories:sortedMeals && sortedMeals.map(item => item._id),
      labels: {
        style: {
          fontSize: '12px',
        }
      }
    },
    yaxis: {
      categories:sortedMeals && sortedMeals.map(item => item.count),
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    }
  };



  const sortedMembershipChart = membershipChart && membershipChart.sort((a, b) => a.x - b.x);
  const membershipOptions = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false // Set to false to hide the default side menu
      }
    },
    colors: ['#6A8DB5'],
    series: [
      {
        name: 'Membership Fees in ($)',
        data: membershipChart && membershipChart.map(item => item.y)
      }
    ],
    xaxis: {
      categories:sortedMembershipChart && sortedMembershipChart.map(item => item.x),
      labels: {
        style: {
          fontSize: '12px',
        }
      }
    },
    yaxis: {
      categories:membershipChart && membershipChart.map(item => item.y),
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    }
  };

  const sortedCommissionChart = commissionChart && commissionChart.sort((a, b) => a.x - b.x);
  const commissionOptions = {
    chart: {
      type: 'line',
      height: 350,
      toolbar: {
        show: false // Set to false to hide the default side menu
      }
    },
    colors: ['#D6A7A7'],
    series: [
      {
        name: 'Commissions in ($)',
        data: commissionChart && commissionChart.map(item => item.y.toFixed(0))
      }
    ],
    xaxis: {
      categories:sortedCommissionChart && sortedCommissionChart.map(item => item.x),
      labels: {
        style: {
          fontSize: '12px',
        }
      }
    },
    yaxis: {
      categories:commissionChart && commissionChart.map(item => item.y),
      labels: {
        style: {
          fontSize: '12px'
        }
      }
    }
  };


  return (
    <>
      <StyledRoot>
        <Header onOpenNav={() => setOpen(true)} />

        <Nav openNav={open} onCloseNav={() => setOpen(false)} />

        <Main>
          <Helmet>
            <title> Dashboard </title>
          </Helmet>

          <Container maxWidth="xl">
            <Typography variant="h4" sx={{ mb: 5 }}>
              Hi, Welcome{" "}
              {getAuthUser().first_name + " " + getAuthUser().last_name}
            </Typography>

            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Patrons"
                  total={allCount.patronCount}
                  icon={"uil:user"}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Meal Hosts"
                  total={allCount.mealhostCount}
                  color="info"
                  icon={"uil:user"}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Completed Orders"
                  total={allCount.paidOrdersCount}
                  color="warning"
                  icon={"mdi:food"}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={3}>
                <AppWidgetSummary
                  title="Active Subscriptions"
                  total={allCount.subscriptionCount}
                  color="success"
                  icon={"nimbus:money"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={5}>
               <Grid item xs={12} sm={6} md={4}>
                <Typography align="center"><b>Mealhosts by country</b></Typography>          
               {/* <Chart options={mealhostOptions} series={mealhostByCountry && Object.values(mealhostByCountry)} type={mealhostOptions.chart.type}  /> */}
            <Chart options={mealhostOptions} series={mealhostOptions && mealhostOptions.series} type={mealhostOptions.chart.type}  />
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography align="center"><b>Patrons by country</b></Typography>          
               {/* <Chart options={patronOptions} series={patronByCountry && Object.values(patronByCountry)} type={patronOptions.chart.type}  /> */}
            <Chart options={patronOptions} series={patronOptions && patronOptions.series} type={patronOptions.chart.type}  />

              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Typography align="center"><b>Types of meals offered by mealhosts</b></Typography>          
               {/* <Chart options={mealOptions} series={mealChart && Object.values(mealChart)} type={mealOptions.chart.type}  /> */}
            <Chart options={mealOptions} series={mealOptions && mealOptions.series} type={mealOptions.chart.type}  />
              </Grid>
            </Grid>
            <Grid container spacing={3} mt={5}>
            <Grid item xs={12} sm={6} md={6}>
            <Typography align="center"><b>Membership fees in ($) per Year</b></Typography>          
            <Chart options={membershipOptions} series={membershipOptions && membershipOptions.series} type={membershipOptions.chart.type}  />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
            <Typography align="center"><b>Commission in ($) per Year</b></Typography>          
            <Chart options={commissionOptions} series={commissionOptions && commissionOptions.series} type={commissionOptions.chart.type}  />
            </Grid>
            </Grid>
          </Container>
        </Main>
      </StyledRoot>
    </>
  );
}
