import { useState } from "react";
import { Navigate } from "react-router";

export const PatronRoute = ({ children }) => {
  const [role, setRole] = useState(localStorage.getItem("Role"));

  if (role === "Patron") {
    return children;
  }

  return <Navigate to="/mealhost-home" />;
};

export const MealHostRoute = ({ children }) => {
  const [role, setRole] = useState(localStorage.getItem("Role"));

  if (role === "MealHost") {
    return children;
  }

  return <Navigate to="/patron-home" />;
};

export const AdminRoleRoute = ({ children }) => {
  const [role, setRole] = useState(localStorage.getItem("Role"));

  if (role === "Admin") {
    return children;
  }

  return <Navigate to="/admin/dashboard/login" />;
};
