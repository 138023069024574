import axios from "axios";
import React, { useEffect, useState } from "react";
import { getAuthUser } from "../Authentication/authHelper";
import { mainServerAppUrl } from "../../apis/mainApi";
import { SideMenu } from "../Side-Menu/sideMenu";
import moment from "moment";
import { Pagination } from "../Pagination/pagination";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const PatronMealHistory = () => {
  const [orders, setOrders] = useState([]);
  const [noOrder, setNoOrder] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);

  const handleCurrentRecords = (currentRecords) => {
    setPaginatedData(currentRecords);
  };

  const data = {
    patron_id: getAuthUser().membership_no,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .post(mainServerAppUrl + "/user/patron/history", data)
      .then((result) => {
        setOrders(result.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          setNoOrder(true);
        }
      });
  }, []);

  return (
    <div className="max-resolution">
       <div className="home-section-1 position-relative">
          <span className="text_msg">PATRON YOUR MEALS HISTORY</span>
        </div>
    <Header/>
      <main>
        <div className="home-section-1 position-relative home-section-2">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="meal_history_section centered pb-4">
          {noOrder && (
            <div className="custom-form centered pb-4">
              <form>
                <div className="col-12 not_found">
                  <p>No Meal History found.</p>
                </div>
              </form>
            </div>
          )}
          {paginatedData.map((order, ind) => (
            <div className="meal_history_box" key={ind}>
              <div className="meal_host_name pb-2">
                <span className="label">
                  Membership No. - {order.mealhost_id}
                </span>
              </div>
              <div className="meal_host_name pb-2">
                <span className="label">
                  Meal Host Name - {order.meal_host}
                </span>
              </div>
              <div className="meal_host_name pb-2">
                <span className="label">Meal Name - {order.meal_name}</span>
              </div>
              <div className="meal_host_date pb-2">
                <span className="label">
                  Date -{" "}
                  {moment
                    .utc(order.ordered_On)
                    .local()
                    .format("DD-MM-YYYY HH:mm")}
                </span>
              </div>
              <div className="meal_host_price">
                <span className="label">
                  Meal Price - ${order.price * order.order_quantity}
                </span>
              </div>
            </div>
          ))}
        </div>
        <Pagination data={orders} handleCurrentRecords={handleCurrentRecords} />
      </main>
      <Footer/>
    </div>
  );
};
