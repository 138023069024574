import React, { useEffect, useState } from "react";
import { SideMenu } from "../Side-Menu/sideMenu";
import { FormComponent } from "./formComponent";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";
import { useLocation } from "react-router-dom";
import { NavMenu } from "../Side-Menu/navmenu";

export const MealHostHome = () => {
  const location = useLocation();
  const [displayStates, setDisplayStates] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("Role", "MealHost");
  }, []);

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleDayCheckboxClick = (day) => {
    setDisplayStates((prevDisplayStates) => ({
      ...prevDisplayStates,
      [day]: !prevDisplayStates[day]
    }));
  };

  const handleMealCheckboxClick = (day, mealType) => {
    setDisplayStates((prevDisplayStates) => ({
      ...prevDisplayStates,
      [day]: {
        ...prevDisplayStates[day],
        [mealType]: !prevDisplayStates[day]?.[mealType]
      }
    }));
  };

  const renderDay = (day) => {
    const dayDisplayState = displayStates[day] || {};
    const displayBreakfast = dayDisplayState.Breakfast ? "block" : "none";
    const displayLunch = dayDisplayState.Lunch ? "block" : "none";
    const displayDinner = dayDisplayState.Dinner ? "block" : "none";

    return (
      <div key={day} className="col-12">
        {/* Render code for each day */}
        <table className="table">
          <tbody>
            <tr>
              <th scope="col">{day}</th>
              <th scope="col">-</th>
              <th scope="col">
                <input
                  type="checkbox"
                  className="input-checkbox"
                  value={day}
                  onClick={() => handleDayCheckboxClick(day)}
                />
              </th>
            </tr>
          </tbody>
        </table>
        <div
          className="select-day-meal"
          id={day}
          style={{ display: displayStates[day] ? "block" : "none" }}
        >
          <div className="row my-2">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center add-meal">
                <span className="days_text"> Breakfast</span>
                <input
                  type="checkbox"
                  className="input-checkbox day-meal"
                  value={`${day}-Breakfast`}
                  onClick={() => handleMealCheckboxClick(day, "Breakfast")}
                />
              </div>
              {displayBreakfast === "block" && (
                <FormComponent mealDay={day} mealType={"Breakfast"} display={displayBreakfast} />
              )}
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center add-meal">
                <span className="days_text">LUNCH</span>
                <input
                  type="checkbox"
                  className="input-checkbox day-meal"
                  value={`${day}-Lunch`}
                  onClick={() => handleMealCheckboxClick(day, "Lunch")}
                />
              </div>
              {displayLunch === "block" && (
                <FormComponent mealDay={day} mealType={"Lunch"} display={displayLunch} />
              )}
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center add-meal">
                <span className="days_text">DINNER</span>
                <input
                  type="checkbox"
                  className="input-checkbox day-meal"
                  value={`${day}-Dinner`}
                  onClick={() => handleMealCheckboxClick(day, "Dinner")}
                />
              </div>
              {displayDinner === "block" && (
                <FormComponent mealDay={day} mealType={"Dinner"} display={displayDinner} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-resolution">
              <section className="title_box">
          {location.state === "mymenu" ? (
            <h2>MEAL HOST ENTER MY MENU</h2>
          ) : (
            <h2>MEAL HOST MY HOME</h2>
          )}
        </section>
      <Header />
      <section className="title_box">
        <div className="container">
          <div className="drop-down position-relative">
            <SideMenu />
            {/* <NavMenu/> */}
          </div>
          <p>
            Use culinary skills offer regional cuisines and become financially successful.
          </p>
          {/* {location.state === "mymenu" ? (
            <h2>MEAL HOST MY MENU</h2>
          ) : (
            <h2>MEAL HOST MY HOME</h2>
          )} */}
        </div>
      </section>
      <main className="pb-0 pt-0">
        <div className="main_border pt-4">
          <div className="container">
            <div className="mb-4 select-menu-days menu_content_box row justify-content-center ">
              {days.map((day) => renderDay(day))}
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default MealHostHome;










// below lies the old code


// import React, { useEffect } from "react";
// import { SideMenu } from "../Side-Menu/sideMenu";
// import { FormComponent } from "./formComponent";
// import { Header } from "../Header/header";
// import { Footer } from "../Footer/footer";

// export const MealHostHome = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div className="max-resolution">
//       <Header/>
//       <section className="title_box">
//         <div className="container">
//           <div className="drop-down position-relative">
//             <SideMenu />
//           </div>
//           <p>
//             Use culinary skills offer regional cuisines and become financially
//             successful.{" "}
//           </p>
//           <h2>MEAL HOST MY HOME</h2>
//         </div>
//       </section>
//       <main className="pb-0 pt-0">
//         <div className="main_border pt-4">
//           <div className="container">
//             <div className="mb-4 select-menu-days menu_content_box row justify-content-center ">
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Monday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           name="meal_day"
//                           value="Monday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Monday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Monday-Breakfast"
//                           name="meal_type"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Monday"}
//                         mealType={"Breakfast"}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Monday-Lunch"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Monday"} mealType={"Lunch"} />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Monday-Dinner"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Monday"} mealType={"Dinner"} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table ">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Tuesday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Tuesday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Tuesday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Tuesday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Tuesday"}
//                         mealType={"Breakfast"}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Tuesday-Lunch"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Tuesday"} mealType={"Lunch"} />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Tuesday-Dinner"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Tuesday"} mealType={"Dinner"} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Wednesday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Wednesday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Wednesday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Wednesday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Wednesday"}
//                         mealType={"Breakfast"}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Wednesday-Lunch"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Wednesday"} mealType={"Lunch"} />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Wednesday-Dinner"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Wednesday"}
//                         mealType={"Dinner"}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Thursday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Thursday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Thursday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Thursday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Thursday"}
//                         mealType={"Breakfast"}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Thursday-Lunch"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Thursday"} mealType={"Lunch"} />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Thursday-Dinner"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Thursday"} mealType={"Dinner"} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Friday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Friday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Friday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Friday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Friday"}
//                         mealType={"Breakfast"}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Friday-Lunch"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Friday"} mealType={"Lunch"} />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Friday-Dinner"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Friday"} mealType={"Dinner"} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Saturday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Saturday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Saturday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Saturday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Saturday"}
//                         mealType={"Breakfast"}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Saturday-Lunch"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Saturday"} mealType={"Lunch"} />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Saturday-Dinner"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Saturday"} mealType={"Dinner"} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Sunday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Sunday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Sunday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Sunday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Sunday"}
//                         mealType={"Breakfast"}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Sunday-Lunch"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Sunday"} mealType={"Lunch"} />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Sunday-Dinner"
//                         />
//                       </div>
//                       <FormComponent mealDay={"Sunday"} mealType={"Dinner"} />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="menu_center_box py-4">
//           <div className="container">
//             <div className="row">
//               <div className="col-12 text-center">
//                 <p>
//                   Above you have option to fill seven Meal Combos for Breakfast,
//                   Lunch and Dinner each. You can limit the need to prepare
//                   multiple meals daily, you can repeat. So, you will need to
//                   prepare only one Meal Combo each for Breakfast, Lunch and
//                   Dinner any day of the week.
//                 </p>
//                 <h3>YOU CAN DUPLICATE THE COMBO MEALS</h3>
//               </div>
//             </div>
//             {/* <div className="row">
//               <div className="col-12">
//                 <div className="all-meal-btn">
//                   <input type="submit" />
//                 </div>
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </main>
//       <Footer/>
//     </div>
//   );
// };
