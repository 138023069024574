import React, { useState, useEffect } from "react";

export const Pagination = ({ data, handleCurrentRecords }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(5);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = data?.slice(indexOfFirstRecord, indexOfLastRecord);
  const totalPages = Math.ceil(data?.length / recordsPerPage);

  useEffect(() => {
    if (handleCurrentRecords) {
      handleCurrentRecords(currentRecords);
  // console.log("data",data)
      // console.log(currentRecords,"currentRecords")
    }
  }, [currentPage,data]);

  const nextPage = () => {
    if (currentPage !== totalPages) setCurrentPage(currentPage + 1);
    window.scrollTo(0, 300);
  };
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
    window.scrollTo(0, 300);
  };

  return (
    <>
      {totalPages > 1 && (
        <div className="d-flex justify-content-center">
          <nav aria-label="Page navigation example">
            <ul className="pagination">
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={prevPage}
                  style={{
                    backgroundColor: "#09943e",
                    color: "black",
                    borderColor: "black",
                  }}
                  aria-label="Previous"
                  disabled={currentPage === 1}
                >
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  style={{
                    backgroundColor: "#09943e",
                    color: "black",
                    borderColor: "black",
                    fontFamily: "sans-serif",
                  }}
                  disabled
                >
                  {currentPage}/{totalPages}
                </button>
              </li>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={nextPage}
                  style={{
                    backgroundColor: "#09943e",
                    color: "black",
                    borderColor: "black",
                  }}
                  aria-label="Next"
                  disabled={currentPage === totalPages}
                >
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </>
  );
};
