import axios from "axios";
import React, { useEffect, useState } from "react";
import { getAuthUser } from "../Authentication/authHelper";
import { mainServerAppUrl } from "../../apis/mainApi";
// import { SideMenu } from "../Side-Menu/sideMenu";
import moment from "moment";
import { Pagination } from "../Pagination/pagination";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";
import Typography from "../Dashboard/theme/overrides/Typography";
import { Rating } from "react-simple-star-rating";

export const AcceptedMeals = () => {
  const [patrons, setPatrons] = useState([]);
  const [noMeals, setnoMeals] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);

  const handleCurrentRecords = (currentRecords) => {
    setPaginatedData(currentRecords);
  };

  const data = {
    mealhost_id: getAuthUser().membership_no,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .post(mainServerAppUrl + "/user/mealhost/accepted-meals", data)
      .then((result) => {
        setPatrons(result.data);
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          setnoMeals(true);
        }
      });
  }, []);

  return (
    <div className="max-resolution">
      <section className="title_box">
        <h2>MEAL HOST ACCEPTED ORDERS</h2>
      </section>
      <Header />
      <section className="title_box">
        <div className="container position-relative">
          {/* <SideMenu /> */}
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div className="container position-relative">
          <div className="custom-form centered mb-4">
            {noMeals ? (
              <div
                className="w-100 form-control pt-0"
                style={{ textAlign: "center" }}
              >
                No meals to serve
              </div>
            ) : (
              <div className="w-100 form-control pt-0">
                {paginatedData.map((patronContact, ind) => (
                  <div className="green_box_history mt-4" key={ind}>
                    <div className="row">
                      <div className="col-12">

                        <div className="patrons-details">
                            <p>Order details:</p>
                          <ul className="list-unstyled">
                            <li>
                              {moment(patronContact?.ordered_On).format(
                                "DD-MM-YYYY"
                              )}
                            </li>
                           
                            <li>
                              {patronContact?.meal_name +" ("+ patronContact?.meal_type +")"}
                            </li>
                            <li>
                              {
                                "Qty : "+patronContact?.order_quantity
                                }
                            </li>
                    
                          </ul>
                        </div>

                        <div className="patrons-details">
                            <p>Patron details:</p>
                          <ul className="list-unstyled">
                            <li>
                              {patronContact?.first_name +
                                " " +
                                patronContact?.last_name}
                            </li>
                            <li>
                              {patronContact?.address_line1 +
                                "," +
                                patronContact?.address_line2 +
                                "," +
                                patronContact?.city +
                                "," +
                                patronContact?.state +
                                "-" +
                                patronContact?.zip_code}
                            </li>
                            <li>
                              {"+" +
                                patronContact?.country_code +
                                "-" +
                                patronContact?.phone}
                            </li>
                            <li>{patronContact?.email}</li>
                            <Rating
                          initialValue={patronContact?.ratings}
                          readonly
                          allowFraction
                          size={23}
                          title={patronContact?.ratings}
                        /> <span style={{fontSize:"15px"}}>( {patronContact?.num_of_reviews})</span>
                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <Pagination
          data={patrons}
          handleCurrentRecords={handleCurrentRecords}
        />
      </main>
      <Footer />
    </div>
  );
};
