import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SideMenu } from "../Side-Menu/sideMenu";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const MealhostCodeOfConduct = () => {
  // const [orderConfirmed, setOrderConfirmed] = useState("");

  const navigate = useNavigate();
  // const { state } = useLocation();
  // let checkoutUrl = "";
  // let order = {};
  // if (state) {
  //   checkoutUrl = state.checkoutUrl;
  //   order = state.order;
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-resolution">
    <section className="title_box">
        <h2>Code of Conduct for the Mealhosts</h2>
    </section>
      <Header />
      <section className="title_box">
        <div className="container">
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div
          className="custom-form centered pb-4"
          style={{ paddingTop: "1rem" }}
        >
          <div style={{ fontSize: "16px", minHeight: "140px" }}>
            <ul className="px-2">
              <li>
                {" "}
                <b> Quality Assurance:</b> Maintain high hygiene and food safety standards in meal preparation. {" "}
              </li>
              <li>
                {" "}
                <b> Transparency:</b> List all ingredients and potential allergens in the meal descriptions.{" "}
              </li>
              <li>
                {" "}
                <b>Punctuality</b> Serve meals at the agreed times  {" "}
              </li>
              <li>
                {" "}
                <b> Compliance:</b> Adhere to all local health regulations and food preparation and handling guidelines. 
              </li>
              <li>
                {" "}
                <b>Do not Accept Tipping or any Solicitations </b>
              </li>
              <li>
                {" "}
                <b> Professionalism:</b> Communicate respectfully and professionally with patrons. {" "}
              </li>
              <li>
                {" "}
                <b> Responsiveness:</b> Address Patron's inquiries and feedback promptly and courteously. 
              </li>
              <li>
                {" "}
                <b> Respect for Privacy:</b> Protect patrons' personal information and do not share it without consent. 
              </li>
              <li>
                {" "}
                <b> Integrity:</b> Uphold honesty and integrity in all interactions. 
              </li>
              <li>
                {" "}
                <b> Inclusivity:</b>Respect the diverse backgrounds and
                preferences of all patrons and meal providers.{" "}
              </li>
              <li>
                {" "}
                <b> Accountability:</b> Take responsibility for actions and
                decisions, striving to resolve issues amicably and fairly.{" "}
              </li>
              <li>
                {" "}
                <b> Safety:</b> Prioritize the safety and well-being of all
                parties{" "}
              </li>
              <li>
                {" "}
                <b> Continuous Improvement:</b> Commit to continuous improvement
                in service quality and customer satisfaction.
              </li>
              <li>
                {" "}
                <b> By adhering to the Code of Conduct:</b> contribute to a
                positive, respectful, and high-quality experience with Homey
                Foods.
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-12 p-2" style={{ marginTop: "5rem" }}>
            <button
                        onClick={() =>
                          (window.location.href = "/mealhost-instructions")
                        }
                        // onClick={()=> <Navigate to="/mealhost-instructions" />}
                        value="Instruction"
                        className="btn w-100"
                        type="button"
                      >
                        Click On Instructions To Make Your Menu
                      </button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
