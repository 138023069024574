// component
import SvgColor from "../../../components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor
    src={require(`../../../../../assets/illustrations/navbar/${name}.svg`)}
    sx={{ width: 1, height: 1 }}
  />
);

const navConfig = [
  {
    title: "dashboard",
    path: "/admin/dashboard",
    icon: icon("ic_analytics"),
  },
  {
    title: "patrons",
    path: "/admin/patrons",
    icon: icon("ic_user"),
  },
  {
    title: "meal hosts",
    path: "/admin/mealhosts",
    icon: icon("ic_user"),
  },
  {
    title: "Emails",
    icon: icon("email"),
    subItems: [
      {
        title: "Patron",
        path: "/admin/communications/Patron",
      },
      {
        title: "Meal Host",
        path: "/admin/communications/MealHost",
      },
      {
        title: "Guest",
        path: "/admin/communications/Guest",
      },
    ],
  },
  {
    title: "Marketing",
    path: "/admin/marketing",
    icon: icon("marketing"),
  },
];

export default navConfig;
