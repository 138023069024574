import { useState } from "react";
import { isLoggedIn } from "./authHelper";
import { Navigate } from "react-router";

export const PrivateRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn());

    if (isAuthenticated) {
        return children
    }

    return <Navigate to="/user-login" />
}

export const PrivateAdminRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn());

    if (isAuthenticated) {
        return children
    }

    return <Navigate to="/admin/dashboard/login" />
}

export const RedirectRoute = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(isLoggedIn());
    const [role, setRole] = useState(localStorage.getItem("Role"));

    if (isAuthenticated && role === 'Patron') {
        return <Navigate to="/patron-home" />
    }
    else if (isAuthenticated && role === 'MealHost') {
        return <Navigate to="/mealhost-home" />
    }
    return children
}