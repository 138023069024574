import React, { useEffect } from "react";
import { getAuthUser } from "../Authentication/authHelper";
import moment from "moment";
import { useState } from "react";
import { mainServerAppUrl } from "../../apis/mainApi";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import { SideMenu } from "../Side-Menu/sideMenu";
import { toast } from "react-hot-toast";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const PatronRatings = () => {
  const [rating, setRating] = useState(0);
  const [isActive, setIsActive] = useState(false);
  // const [mealHost, setMealHost] = useState([]);
  // const [mealHostId, setMealHostId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location)

  let firstName = getAuthUser()?.first_name;
  let lastName = getAuthUser()?.last_name;
  let patronId = getAuthUser()?.membership_no;

  // useEffect(() => {
  //   axios
  //     .post(mainServerAppUrl + "/user/get-mealhost", { patronId: patronId })
  //     .then((result) => {
  //       // console.log(result.data);
  //       setMealHost(result.data)
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const submitRating = (e) => {
    e.preventDefault();

    const data = {
      rating: Number(rating),
      name: firstName + lastName,
      // mealhost_id: mealHostId,
      mealhost_id: location?.state?.mealhost_id,
      patron_id: patronId,
      order_id: location?.state?._id,
    };

    // console.log(data);

    if (data.rating) {
      axios
        .post(mainServerAppUrl + "/user/patron/ratings", data)
        .then((result) => {
          toast.success(result.data.message, {
            id: "rating success",
            duration: 3000,
          });
          setTimeout(() => {
            navigate("/patron-home");
          }, 4000);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 404) {
            toast.error(error.response.data.message, {
              id: "not found",
              duration: 3000,
            });
            // setTimeout(() => {
            //   navigate("/mealhost-not-found");
            // }, 4000);
          } else {
            toast.error(error.response.data.message, {
              id: "error",
              duration: 3000,
            });
          }
        });
    } else {
      toast.error("Please select a star", {
        id: "error",
        duration: 3000,
      });
    };
  };

  const handleClick = (e) => {
    setRating(e.currentTarget.getAttribute("value"));
    setIsActive(e.currentTarget.getAttribute("value"));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-resolution">
      <div className="home-section-1 ">
          <span className="text_msg">PATRON RATE YOUR MEAL HOST</span>
        </div>
      <Header/>
      <main>
        <div className="home-section-1 position-relative home-section-2">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="custom-form centered pb-4">
          <form onSubmit={submitRating}>
            <div className="row">
              <div className="col-12">
                {/* <select
                  className="w-100 form-control pt-0"
                  onChange={(e) => {
                    setMealHostId(e.target.value);
                  }}
                  defaultValue={"DEFAULT"}
                >
                  <option disabled key="DEFAULT" value="DEFAULT">
                    Select Mealhost
                  </option>
                  {mealHost &&
                    mealHost.map((host, i) => (
                      <option value={host.mealhost_id} key={i}>
                        {host.meal_host + " (" + host.mealhost_id + ")"}
                      </option>
                    ))}
                </select> */}

                <input
                  type="text"
                  name="date"
                  placeholder="Your Meal Host's Number"
                  // value={"Your Meal Host's Number - " + location?.state?.mealhost_id}
                  value={location?.state?.meal_host + " ("+location?.state?.mealhost_id+")"}
                  className="w-100 form-control pt-0"
                  readOnly
                />
                
              </div>
              <div className="col-12">
                <input
                  type="date"
                  name="date"
                  placeholder="Date"
                  value={moment(new Date(location?.state?.ordered_On)).format("YYYY-MM-DD")}
                  className="w-100 form-control "
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  value={firstName}
                  className="w-100 form-control"
                  readOnly
                />
              </div>
              <div className="col-12">
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  value={lastName}
                  className="w-100 form-control "
                  readOnly
                />
              </div>
              <div className="col-12">
                <div className="rating_section">
                  <span
                    className="rating_star"
                    onClick={handleClick}
                    value={1}
                    style={{
                      scale: isActive == 1 ? "1.2" : "",
                      color: isActive == 1 ? "white" : "",
                      cursor: "pointer",
                    }}
                  >
                    <span className="rating_text">Poor</span>
                  </span>
                  <span
                    className="rating_star"
                    onClick={handleClick}
                    value={2}
                    style={{
                      scale: isActive == 2 ? "1.2" : "",
                      color: isActive == 2 ? "white" : "",
                      cursor: "pointer",
                    }}
                  >
                    <span className="rating_text">Acceptable</span>
                  </span>
                  <span
                    className="rating_star"
                    onClick={handleClick}
                    value={3}
                    style={{
                      scale: isActive == 3 ? "1.2" : "",
                      color: isActive == 3 ? "white" : "",
                      cursor: "pointer",
                    }}
                  >
                    <span className="rating_text">Good</span>
                  </span>
                  <span
                    className="rating_star"
                    onClick={handleClick}
                    value={4}
                    style={{
                      scale: isActive == 4 ? "1.2" : "",
                      color: isActive == 4 ? "white" : "",
                      cursor: "pointer",
                    }}
                  >
                    <span className="rating_text">Very Good</span>
                  </span>
                  <span
                    className="rating_star"
                    onClick={handleClick}
                    value={5}
                    style={{
                      scale: isActive == 5 ? "1.2" : "",
                      color: isActive == 5 ? "white" : "",
                      cursor: "pointer",
                    }}
                  >
                    <span className="rating_text">Excellent</span>
                  </span>
                </div>
              </div>
            </div>

            <div className="col-12 p-0">
              <input type="submit" value="SUBMIT" className="btn w-100" />
            </div>
          </form>
        </div>
      </main>
      <Footer/>
    </div>
  );
};
