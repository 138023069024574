import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import React, { useEffect, useState, useRef } from "react";
import { ScrollToFieldError } from "../Validation/scrollToFieldError";
import { mealHostHomeValidation } from "../Validation/ValidationSchema";
import { toast } from "react-hot-toast";
import { mainServerAppUrl } from "../../apis/mainApi";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import imageCompression from 'browser-image-compression';

export const FormComponent = ({
  mealDay,
  mealType,
  isUpdate,
  mealData,
  display,
}) => {
  const [disableBtn, setDisableBtn] = useState(false);
  // const [showWebcam, setShowWebcam] = useState(false);

  const config = {
    headers: {
      "Content-type": "multipart/form-data",
    },
  };

  const getInitialValues = () => {
    if (isUpdate && mealData) {
      const filteredData = mealData.filter(
        (meal) => meal.meal_day === mealDay && meal.meal_type === mealType
      );

      return filteredData.map((meal) => ({
        meal_id: meal.meal_id,
        preferences: meal.preferences,
        allergies: meal.allergies,
        description: meal.description,
        meal_name: meal.meal_name,
        price: meal.price,
        image: meal.imageUrl,
        meal_type: mealType,
        meal_day: mealDay,
      }));
    } else {
      return [
        {
          meal_id: uuidv4(),
          preferences: [],
          allergies: [],
          description: "",
          meal_name: "",
          price: "",
          image: null,
          meal_day: mealDay,
          meal_type: mealType,
          imagePreview: null,
        },
      ];
    }
  };

  // const handleImageUpload = (event, index, values, setFieldValue) => {
  //   const file = event.target.files[0];

  //   if (file) {
  //     if (file.size > 10 * 1024 * 1024) {
  //       alert("Image size should not exceed 10 MB");
  //       event.target.value = "";
  //       return;
  //     }
  //   }
  //   const newMenu = [...values.menu];
  //   newMenu[index].image = file;
  //   setFieldValue("menu", newMenu);

  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     setFieldValue(`menu[${index}].imagePreview`, reader.result);
  //   };
  //   reader.readAsDataURL(file);
  // };


  const handleImageUpload = async (event, index, values, setFieldValue) => {
    const file = event.target.files[0];
  
    if (file) {
      if (file.size > 10 * 1024 * 1024) {
        alert("Image size should not exceed 10 MB");
        event.target.value = "";
        return;
      }
    }
  
    try {
      const options = {
        maxSizeMB: 0.5, // Adjust the maximum file size as needed
        // maxWidthOrHeight: 800, // Adjust the maximum width or height as needed
      };
  
      // Compress the image using browser-image-compression
      const compressedFile = await imageCompression(file, options);
      // console.log(file,"file")
      // console.log(compressedFile,"compressed")

      const newMenu = [...values.menu];
      newMenu[index].image = compressedFile; // Store the compressed image in your form state
      setFieldValue('menu', newMenu);
  
      // Note: You might need to adjust this part based on how you handle image previews in your code
      const reader = new FileReader();
      reader.onload = () => {
        setFieldValue(`menu[${index}].imagePreview`, reader.result);
      };
      reader.readAsDataURL(compressedFile);
    } catch (error) {
      console.error('Error compressing image:', error);
    }
  };

  const deleteMeals = (values, index, remove) => {
    // console.log("values", values);
    // console.log("index", index);

    confirmAlert({
      message: "Do you want to delete this meal?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            isUpdate
              ? axios
                  .delete(
                    mainServerAppUrl +
                      "/delete-meal/" +
                      values.menu[index].meal_id
                  )
                  .then((result) => {
                    toast.success(result?.data?.message, {
                      id: "delete",
                      duration: 3000,
                    });
                    remove(index);
                  })
                  .catch((error) => {
                    console.log(error);
                    toast.error(error?.response?.data?.message, {
                      id: "deleteFail",
                      duration: 3000,
                    });
                  })
              : remove(index);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleSubmit = (values, resetForm) => {
    if (isUpdate) {
      axios
        .patch(mainServerAppUrl + "/update-meal", values, config)
        .then((result) => {
          setDisableBtn(true);
          setTimeout(() => {
            setDisableBtn(false);
          }, 3000);
          toast.success(result?.data?.message, {
            id: "update",
            duration: 3000,
          });
        })
        .catch((error) => {
          console.log(error);
          toast.error(error?.response?.data?.message, {
            id: "updateFail",
            duration: 3000,
          });
        });
    } else {
      axios
        .post(mainServerAppUrl + "/meal", values, config)
        .then((result) => {
          if (result?.status === 201) {
            toast.success("Menu Item added", {
              id: "success",
              duration: 3000,
            });
            resetForm();
          } else {
            toast.error(result?.data?.message, {
              id: "subscription",
              duration: 3000,
            });
          }
        })
        .catch((error) => {
          toast.error(error?.response?.data?.message, {
            id: "failure",
            duration: 3000,
          });
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        validateOnMount={true}
        validateOnChange={true}
        validateOnBlur={true}
        validationSchema={mealHostHomeValidation}
        initialValues={{
          menu: getInitialValues(),
        }}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({ errors, touched, values, setFieldValue }) => (
          <Form
            className="show-meal"
            id={mealDay + "-" + mealType}
            style={{ display: display }}
          >
            <FieldArray name="menu">
              {({ insert, remove, push }) => (
                <div>
                  {values.menu.length > 0 &&
                    values.menu.map((menus, index) => (
                      <div key={index}>
                        <div className="comment_box my-2">
                          <div className="box_count_feedback br-bottom">
                            <label>Description</label>
                            <Field
                              type="text"
                              name={`menu.${index}.description`}
                              className={
                                "form-control" +
                                (errors.menu?.[index]?.description &&
                                touched.menu?.[index]?.description
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name={`menu.${index}.description`}
                              className="invalid-feedback"
                              component="div"
                              style={{
                                color: "maroon",
                                position: "absolute",
                                marginTop: "0.012rem",
                              }}
                            />
                          </div>
                          <div className="box_count_feedback br-left br-bottom">
                            <label
                              htmlFor={`file-upload-${index}-${mealType}-${mealDay}`}
                              className="custom-file-upload"
                            >
                              {values.menu[index].imagePreview ||
                              (isUpdate && values.menu[index].image) ? (
                                <img
                                  src={
                                    values.menu[index].imagePreview ||
                                    `${process.env.REACT_APP_BACKEND_URL}/uploads/` +
                                      values.menu[index]?.image
                                  }
                                  alt="Preview"
                                  style={{ width: "80px" }}
                                />
                              ) : (
                                <div>
                                  <i
                                    className="fa fa-sign-out"
                                    aria-hidden="true"
                                  ></i>
                                  <label>Upload Photo</label>
                                </div>
                              )}
                            </label>
                            <input
                              id={`file-upload-${index}-${mealType}-${mealDay}`}
                              type="file"
                              name={`menu.${index}.image`}
                              accept="image/*"
                              capture="user"
                              onChange={(event) =>
                                handleImageUpload(
                                  event,
                                  index,
                                  values,
                                  setFieldValue
                                )
                              }
                              // accept=".png, .jpg, .jpeg"
                            />

                            <ErrorMessage
                              name={`menu[${index}].image`}
                              component="div"
                              className="form_invalid_1"
                              style={{
                                color: "maroon",
                                marginTop: "0rem",
                                position: "absolute",
                              }}
                            />
                          </div>
                          <div className="box_count_feedback">
                            <label>Meal Name</label>
                            <Field
                              type="text"
                              name={`menu.${index}.meal_name`}
                              className={
                                "form-control" +
                                (errors.menu?.[index]?.meal_name &&
                                touched.menu?.[index]?.meal_name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name={`menu.${index}.meal_name`}
                              component="div"
                              className="invalid-feedback"
                              style={{
                                color: "maroon",
                                position: "absolute",
                                marginTop: "0.012rem",
                              }}
                            />
                          </div>
                          <div
                            className="box_count_feedback br-left"
                            style={{ position: "relative" }}
                          >
                            <label>Price</label>
                            <span
                              style={{
                                position: "absolute",
                                left: "5px",
                                color: "white",
                                fontWeight: "bold",
                                marginTop: "0.2rem",
                              }}
                            >
                              $
                            </span>
                            <Field
                              type="number"
                              name={`menu.${index}.price`}
                              className={
                                "form-control" +
                                (errors.menu?.[index]?.price &&
                                touched.menu?.[index]?.price
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name={`menu.${index}.price`}
                              component="div"
                              className="invalid-feedback"
                              style={{
                                color: "maroon",
                                position: "absolute",
                                marginTop: "0.012rem",
                              }}
                            />
                          </div>
                        </div>
                        <div className="mb-4 menu_content_box row justify-content-center ">
                          <div className="col-12">
                            <h3 className="h3_head">Use Following Option</h3>
                          </div>
                          <div className="col-6 ">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">Veg</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      name={`menu.${index}.preferences`}
                                      value="Veg"
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6 ">
                            <table className="table ">
                              <tbody>
                                <tr>
                                  <th scope="col">Non-Veg</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Non-Veg"
                                      name={`menu.${index}.preferences`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6 ">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">Vegan</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Vegan"
                                      name={`menu.${index}.preferences`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">Halal</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Halal"
                                      name={`menu.${index}.preferences`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6 ">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">Kosher</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Kosher"
                                      name={`menu.${index}.preferences`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                            <ErrorMessage
                              name={`menu.${index}.preferences`}
                              className="form_invalid_1"
                              component="div"
                            />
                          </div>
                        </div>
                        <div className="mb-4 menu_content_box row justify-content-center ">
                          <div className="col-12">
                            <h3 className="h3_head">Allergic Ingredients</h3>
                          </div>
                          <div className="col-6 ">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">Eggs</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Eggs"
                                      name={`menu.${index}.allergies`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6 ">
                            <table className="table ">
                              <tbody>
                                <tr>
                                  <th scope="col">Peanuts</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Peanuts"
                                      name={`menu.${index}.allergies`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6 ">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">Shell Fish</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Shell Fish"
                                      name={`menu.${index}.allergies`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">Soy</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Soy"
                                      name={`menu.${index}.allergies`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6 ">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">Gluten</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="Gluten"
                                      name={`menu.${index}.allergies`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-6">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th scope="col">None</th>
                                  <th scope="col">-</th>
                                  <th scope="col">
                                    <Field
                                      type="checkbox"
                                      className="input-checkbox"
                                      value="none"
                                      name={`menu.${index}.none`}
                                    />
                                  </th>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {(isUpdate || values.menu.length >= 2) && (
                          <button
                            type="button"
                            className="all-meal-btn bg-danger"
                            style={{ float: "right", margin: "auto" }}
                            onClick={() => deleteMeals(values, index, remove)}
                          >
                            Remove
                          </button>
                        )}
                      </div>
                    ))}
                  <button
                    className="all-meal-btn"
                    type="button"
                    onClick={() =>
                      push({
                        meal_id: uuidv4(),
                        preferences: [],
                        allergies: [],
                        description: "",
                        meal_name: "",
                        price: "",
                        image: null,
                        meal_type: mealType,
                        meal_day: mealDay,
                        imagePreview: null,
                      })
                    }
                  >
                    Add On
                  </button>
                </div>
              )}
            </FieldArray>
            <button
              className="all-meal-btn w-100"
              type="submit"
              disabled={disableBtn}
            >
              {isUpdate ? "UPDATE" : "SUBMIT"}
            </button>
            <ScrollToFieldError />
          </Form>
        )}
      </Formik>
    </div>
  );
};
