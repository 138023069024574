import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import moment from "moment";
import Visa from "../../assets/images/visa-card.svg";
import MasterCard from "../../assets/images/mastercard.svg";
import Amex from "../../assets/images/amex.svg";
import { getAuthUser } from "../Authentication/authHelper";
import { updatePatronRegistrationValidation, updateRegistrationValidation } from "../Validation/ValidationSchema";
import { mainServerAppUrl } from "../../apis/mainApi";
import { SideMenu } from "../Side-Menu/sideMenu";
import { toast } from "react-hot-toast";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { CountryCodes } from "../../common/countryCodes";
import { ScrollToFieldError } from "../Validation/scrollToFieldError";
import MaskedInput from "react-text-mask";
import { cardNumberMask } from "../Validation/cardNumberMask";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const UpdatePatronRegistration = () => {
  const [updateDetails, setUpdateDetails] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const user = getAuthUser();

  const getCardDetails = () => {
    const cardDetails = user?.card_details?.[0];
    return {
      card_type: cardDetails?.card_type,
      card_expiry: cardDetails?.card_expiry,
      card_number: cardDetails?.card_number,
      cvv: cardDetails?.cvv,
    };
  };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    <div className="max-resolution">
      <div className="home-section-1 position-relative">
          <span className="text_msg">{updateDetails? "PATRON SUCCESSFUL REGISTRATION" :"PATRON UPDATE REGISTRATION"}</span>
          {updateDetails && (
            <span className="text_msg">
              Your details have been successfully updated.
            </span>
          )}
        </div>
    <Header/>
      <main>
        <div className="home-section-1 position-relative home-section-2">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="custom-form centered pb-4">
          <span
            style={{
              fontSize: "19px",
              fontWeight: "700",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Your Membership Number - {user.membership_no}
          </span>
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={updatePatronRegistrationValidation}
            initialValues={{
              registration_date: moment(user?.registration_date).format(
                "YYYY-MM-DD"
              ),
              profile: user?.profile,
              first_name: user?.first_name,
              last_name: user?.last_name,
              dob: {
                day: user?.dob?.day,
                month: user?.dob?.month,
                year: user?.dob?.year
              },
              date_of_birth: user?.date_of_birth,
              address_line1: user?.address_line1,
              address_line2: user?.address_line2,
              city: user?.city,
              phone: user?.phone,
              country: user?.country,
              state: user?.state,
              zip_code: user?.zip_code,
              password: user?.password,
              country_code: user?.country_code,
              email: user?.email,
              card_details: getCardDetails(),
              role: user?.role,
              preferences: user?.preferences,
              allergies: user?.allergies,
            }}
            onSubmit={(values) => {
              axios
                .patch(mainServerAppUrl + "/user/update-details", values)
                .then((result) => {
                  setUpdateDetails(true);
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  toast.success(result?.data?.message, {
                    id: "updateSuccess",
                    duration: 3000,
                  });
                  localStorage.setItem("_token", result?.data?.token);
                })
                .catch((err) => {
                  console.log(err);
                  toast.error(err?.response?.data?.message, {
                    id: "updateFail",
                    duration: 3000,
                  });
                });
            }}
          >
            {({
              errors,
              status,
              touched,
              values,
              handleChange,
              handleBlur,
              setFieldValue,
            }) => (
              <Form >
                <div className="row">
                  <div className="col-12">
                    <Field
                      type="date"
                      name="registration_date"
                      placeholder="Date of Registration"
                      className={
                        "w-100 form-control" +
                        (errors.city && touched.city ? " is-invalid" : "")
                      }
                      disabled
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      type="text"
                      name="first_name"
                      placeholder="First Name"
                      className={
                        "w-100 form-control" +
                        (errors.first_name && touched.first_name
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      type="text"
                      name="last_name"
                      placeholder="Last Name"
                      className={
                        "w-100 form-control" +
                        (errors.last_name && touched.last_name
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                  <div className="col-12">
</div>
<div className="col-12">
                        <br/>
                        <b style={{fontSize:"18px"}}>Date of birth</b>
                      </div>
                      <div className="col-4">
      <Field
        type="number"
        name="dob.day"
        placeholder="Day"
        className={
          "w-100 form-control" +
          (errors.dob?.day && touched.dob?.day ? " is-invalid" : "")
        }
      />
      <ErrorMessage name="dob.day" component="div" className="form_invalid" />
    </div>
    <div className="col-4">
      <Field
        type="number"
        name="dob.month"
        placeholder="Month"
        className={
          "w-100 form-control" +
          (errors.dob?.month && touched.dob?.month ? " is-invalid" : "")
        }
      />
      <ErrorMessage name="dob.month" component="div" className="form_invalid" />
    </div>
    <div className="col-4">
      <Field
        type="number"
        name="dob.year"
        placeholder="Year"
        className={
          "w-100 form-control" +
          (errors.dob?.year && touched.dob?.year ? " is-invalid" : "")
        }
      />
      <ErrorMessage name="dob.year" component="div" className="form_invalid" />
      </div>
                  <div className="col-12">
                    <Field
                      type="text"
                      name="address_line1"
                      placeholder="Address line 1"
                      className={
                        "w-100 form-control" +
                        (errors.address_line1 && touched.address_line1
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="address_line1"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      type="text"
                      name="address_line2"
                      placeholder="Address line 2"
                      className={
                        "w-100 form-control" +
                        (errors.address_line2 && touched.address_line2
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="address_line2"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      type="text"
                      name="city"
                      placeholder="City"
                      className={
                        "w-100 form-control" +
                        (errors.city && touched.city ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="city"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                  <div className="col-12">
                    <div className="select-wrapper">
                      <CountryDropdown
                        name="country"
                        value={values.country}
                        onChange={(_, e) => handleChange(e)}
                        onBlur={handleBlur}
                        className={
                          "w-100 form-control" +
                          (errors.country && touched.country
                            ? " is-invalid"
                            : "")
                        }
                        defaultOptionLabel="Country"
                      />
                      <span className="select_arrow"></span>
                      <ErrorMessage
                        name="country"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="select-wrapper">
                      <RegionDropdown
                        name="state"
                        country={values.country}
                        value={values.state}
                        onChange={(_, e) => handleChange(e)}
                        onBlur={handleBlur}
                        className={
                          "w-100 form-control" +
                          (errors.state && touched.state ? " is-invalid" : "")
                        }
                        defaultOptionLabel="State"
                      />
                      <span className="select_arrow"></span>
                      <ErrorMessage
                        name="state"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <Field
                      type="text"
                      name="zip_code"
                      placeholder="Zip Code"
                      className={
                        "w-100 form-control" +
                        (errors.zip_code && touched.zip_code
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="zip_code"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4">
                        <div className="select-wrapper">
                          <Field
                            as="select"
                            name="country_code"
                            className="w-100 form-control"
                          >
                            {CountryCodes.map((item) => (
                              <option key={item.iso} value={item.code}>
                                +{item.code}
                              </option>
                            ))}
                          </Field>
                          <span className="select_arrow"></span>
                        </div>
                      </div>
                      <div className="col-8">
                        <Field
                          type="number"
                          name="phone"
                          placeholder="Phone"
                          className={
                            "w-100 form-control" +
                            (errors.phone && touched.phone ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="form_invalid"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                      <Field
                        type="text"
                        name="profile"
                        placeholder="Profile"
                        className={
                          "w-100 form-control" +
                          (errors.profile && touched.profile
                            ? " is-invalid"
                            : "")
                        }
                        maxLength={200}
                      />
                      <ErrorMessage
                        name="profile"
                        component="div"
                        className="form_invalid"
                      />
                    </div>



                  <div className="col-12">
                    <Field
                      type="email"
                      name="email"
                      placeholder="Email"
                      className={
                        "w-100 form-control" +
                        (errors.email && touched.email
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="form_invalid"
                    />
                  </div>

                  <div className="col-12">
                      <Field
                        type={showPassword ? "text" : "password"}
                        name="password"
                        placeholder="Password"
                        className={
                          "w-100 form-control" +
                          (errors.password && touched.password
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <div
                        className="p-viewer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <i
                          className={
                            showPassword
                              ? "fa-solid fa-eye fa-lg"
                              : "fa-sharp fa-solid fa-eye-slash fa-lg"
                          }
                        ></i>
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="form_invalid"
                      />
                    </div>


                  
                 
                  <div className="col-12 card_section">
                    <span className="form_heading">Card Type</span>
                    <div className="d-flex align-items-center">
                      <div className="col-3 p-0">
                        <button
                          className="visa_card"
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue("card_details.card_type", "Visa");
                          }}
                        >
                          <img src={Visa} />
                        </button>
                      </div>
                      <div className="col-3 p-0">
                        <button
                          className="master_card"
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(
                              "card_details.card_type",
                              "Master Card"
                            );
                          }}
                        >
                          <img src={MasterCard} />
                        </button>
                      </div>
                      <div className="col-3 p-0">
                        <button
                          className="american_express"
                          onClick={(e) => {
                            e.preventDefault();
                            setFieldValue(
                              "card_details.card_type",
                              "American Express"
                            );
                          }}
                        >
                          <img src={Amex} />
                        </button>
                      </div>
                      <div className="col-3 p-0">
                        <span
                          className="form_heading p-0"
                          onClick={() => {
                            setFieldValue("card_details.card_type", "");
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Others
                        </span>
                      </div>
                    </div>
                    <input
                      type="text"
                      name="card_details.card_type"
                      value={values.card_details.card_type}
                      onChange={handleChange}
                      placeholder="Card Type"
                      className="w-100 form-control"
                      disabled={
                        values.card_details.card_type === "Visa" ||
                        values.card_details.card_type === "American Express" ||
                        values.card_details.card_type === "Master Card"
                      }
                    />
                    <ErrorMessage
                      name="card_details.card_type"
                      component="div"
                      className="form_invalid"
                    />

                    <Field name="card_details.card_number">
                      {({ field }) => (
                        <MaskedInput
                          {...field}
                          mask={cardNumberMask}
                          type="text"
                          guide={false}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Card Number"
                          className={
                            "w-100 form-control" +
                            (errors.card_number && touched.card_number
                              ? " is-invalid"
                              : "")
                          }
                        />
                      )}
                    </Field>

                    <ErrorMessage
                      name="card_details.card_number"
                      component="div"
                      className="form_invalid"
                    />
                    <div className="d-flex" style={{ marginLeft: "-4%" }}>
                      <div>
                        <Field name="card_details.card_expiry">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={[/[0-1]/, /[1-2]/, "/", /[2-9]/, /[3-9]/]}
                              type="text"
                              guide={false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="MM/YY"
                              className={
                                "w-50 form-control ml-3 mr-3 text-center" +
                                (errors.card_expiry && touched.card_expiry
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="card_details.card_expiry"
                          component="div"
                          className="form_invalid"
                        />
                      </div>
                      <div>
                        <Field name="card_details.cvv">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                              type="password"
                              guide={false}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="CVV"
                              className={
                                "w-50 form-control ml-3 mr-3 text-center" +
                                (errors.cvv && touched.cvv ? " is-invalid" : "")
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="card_details.cvv"
                          component="div"
                          className="form_invalid"
                        />
                      </div>
                    </div>
                    <div className="row checkbox_group">
                      <span className="form_heading">Preference</span>
                      <div className="col-12 p-0">
                        <div className="d-flex flex-wrap">
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_1"
                              name="preferences"
                              value="Veg"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_1"
                            >
                              Veg
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_2"
                              name="preferences"
                              value="Non-Veg"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_2"
                            >
                              Non-Veg
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_3"
                              name="preferences"
                              value="Vegan"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_3"
                            >
                              Vegan
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_4"
                              name="preferences"
                              value="Halal"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_4"
                            >
                              Halal
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="preference_5"
                              name="preferences"
                              value="Kosher"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="preference_5"
                            >
                              Kosher
                            </label>
                            <ErrorMessage
                              name="preferences"
                              component="div"
                              className="form_invalid"
                            />
                          </div>
                        </div>
                      </div>
                      <span className="form_heading">Allergies</span>
                      <div className="col-12 p-0">
                        <div className="d-flex flex-wrap">
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_1"
                              name="allergies"
                              value="Eggs"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_1"
                            >
                              Eggs
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_2"
                              name="allergies"
                              value="Peanuts"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_2"
                            >
                              Peanuts
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_3"
                              name="allergies"
                              value="Shell Fish"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_3"
                            >
                              Shell Fish
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_4"
                              name="allergies"
                              value="Soy"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_4"
                            >
                              Soy
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_5"
                              name="allergies"
                              value="Gluten"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_5"
                            >
                              Gluten
                            </label>
                          </div>
                          <div className="col-6 p-0">
                            <Field
                              className="form-check-input"
                              type="checkbox"
                              id="allergies_6"
                              name="allergies"
                              value="None"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="allergies_6"
                            >
                              None
                            </label>
                           
                          </div>
                          <ErrorMessage
                              name="allergies"
                              component="div"
                              className="form_invalid"
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 p-2">
                  <button type="submit" value="REGISTER" className="btn w-100">
                    UPDATE REGISTRATION
                  </button>
                </div>
                <ScrollToFieldError />
              </Form>
            )}
          </Formik>
        </div>
      </main>
      <Footer/>
    </div>
  );
};
