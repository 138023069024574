import React, { useState } from "react";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { resetPasswordValidation } from "../Validation/ValidationSchema";
import { mainServerAppUrl } from "../../apis/mainApi";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { toast } from "react-hot-toast";

export default function ResetPassword() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [tokenStatus, setTokenStatus] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState(path.split("/")[2]);

  useEffect(() => {
    checktokenStatus();
  }, []);

  const checktokenStatus = () => {
    axios
      .post(mainServerAppUrl + `/user/check-token-status/${token}`)
      .then((result) => {
        setTokenStatus(result.data.isActive);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetPassword = (data) => {
    axios
      .post(mainServerAppUrl + "/user/reset/password", {
        newPassword: data.newPassword,
        token: token,
      })
      .then((result) => {
        // console.log(result);
        localStorage.removeItem("_token");
        toast.success(result?.data?.message, { id: "success", duration: 1000 });
        setTimeout(() => {
          navigate("/user-login");
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message, {
          id: "failure",
          duration: 3000,
        });
      });
  };

  return tokenStatus ? (
    <div className="max-resolution">
      <header>
        <div className="header-info">
          <div className="left-part">
            {/* <img src="common/images/food1.png" className="img-fluid" alt="Register Banner"/> */}
            <a href="/" className="site-logo">
              <img src={require("../../assets/images/bannerLogo.png")} />
            </a>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to
              enjoy unique cuisines, healthy regional foods by many who enjoy
              cooking and eager to serve variety of foods.{" "}
            </p>
            <p>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>
      <main>
        <div className="home-section-1">
          <h2>Easy and Fast Access to Homey-FOODS</h2>
          <span className="text_msg">SET UP NEW PASSWORD</span>
        </div>
        <div className="custom-form centered pb-4">
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={resetPasswordValidation}
            initialValues={{
              newPassword: "",
              confirmPassword: "",
            }}
            onSubmit={(values) => {
              // console.log(values)
              resetPassword(values);
            }}
          >
            {({ errors, status, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="New Password"
                      className={
                        "w-100 form-control" +
                        (errors.newPassword && touched.newPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <div
                      className="p-viewer-2"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={
                          showPassword
                            ? "fa-solid fa-eye fa-lg"
                            : "fa-sharp fa-solid fa-eye-slash fa-lg"
                        }
                      ></i>
                    </div>
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="form_invalid"
                    />
                    <Field
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      className={
                        "w-100 form-control" +
                        (errors.confirmPassword && touched.confirmPassword
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                </div>
                <div className="col-12 p-1">
                  <button type="submit" value="change" className="btn w-100">
                    Change Password
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </main>
      <footer>
        <div className="footer-content">
          <div className="disclaimer">
            <p className="pb-2">DISCLAIMER</p>
          </div>
          <p>
          Homey-FOODSis a service that brings Meal
            Hosts and Patrons together. . Homey-FOODS makes no representations,
            endorsements. or warranties of any kind whatsoever of Meal Hosts and
            Customers. Both parties agree to comply to local government laws, be
            of legal age and Homey-FOODS shall not be held responsible from disputes,
            damages and controversies.
          </p>
        </div>
        <div className="footer_bottom">
          <a href="https://www.homey-foods.com" className="site_link">
            www.homey-foods.com
          </a>
          <div className="social-media">
            <ul>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin"></i>{" "}
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <p>© 2023 Homey-FOODS</p>
        </div>
      </footer>
    </div>
  ) : (
    <div className="max-resolution">
      <header>
        <div className="header-info">
          <div className="left-part">
            {/* <img src="common/images/food1.png" className="img-fluid" alt="Register Banner"/> */}
            <a href="/" className="site-logo">
              <img src={require("../../assets/images/bannerLogo.png")} />
            </a>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to
              enjoy unique cuisines, healthy regional foods by many who enjoy
              cooking and eager to serve variety of foods.{" "}
            </p>
            <p>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>
      <main>
        <div className="home-section-1">
          <h2>Easy and Fast Access to Homey-FOODS</h2>
          <span className="text_msg">SET UP NEW PASSWORD</span>
        </div>
        <div className="custom-form centered pb-4">
          <form>
            <div className="col-12 not_found">
              <p>This link has been expired!</p>
            </div>
          </form>
        </div>
      </main>
      <footer>
        <div className="footer-content">
          <div className="disclaimer">
            <p className="pb-2">DISCLAIMER</p>
          </div>
          <p>
          Homey-FOODS is a service that brings Meal
            Hosts and Patrons together. Homey-FOODS makes no representations,
            endorsements. or warranties of any kind whatsoever of Meal Hosts and
            Customers. Both parties agree to comply to local government laws, be
            of legal age and Homey-FOODS shall not be held responsible from disputes,
            damages and controversies.
          </p>
        </div>
        <div className="footer_bottom">
          <a href="https://www.homey-foods.com" className="site_link">
            www.homey-foods.com
          </a>
          <div className="social-media">
            <ul>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin"></i>{" "}
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <p>© 2023 Homey-FOODS</p>
        </div>
      </footer>
    </div>
  );
}
