import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SideMenu } from "../Side-Menu/sideMenu";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const MealHostNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-resolution">
       <div className="home-section-1 position-relative">
          {/* <SideMenu /> */}
          <span className="text_msg">PATRON AVAILABLE MEAL HOSTS</span>
        </div>
      <Header/>
      <main>
        <div className="home-section-1 position-relative">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="custom-form centered pb-4">
          <form>
            <div className="col-12 not_found">
              <p>None available at this time to meet your criteria.</p>
            </div>

            <div className="col-12 p-0">
              <button
                className="btn w-100"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/patron-home");
                }}
              >
                PLEASE TRY AGAIN
              </button>
            </div>
          </form>
        </div>
      </main>
      <Footer/>
    </div>
  );
};
