import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui
import { Box, List, ListItemText } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';
import { useState } from 'react';
import expand_more from "../../../../assets/illustrations/navbar/expand_more.svg";
import chevron_left from "../../../../assets/illustrations/navbar/chevron_left.svg";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      icon: PropTypes.node,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          path: PropTypes.string.isRequired,
        })
      ),
    })
  ),
};

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info, subItems } = item;
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleOpenDropdown = () => {
    setOpenDropdown(true);
  };

  const handleCloseDropdown = () => {
    setOpenDropdown(false);
  };

  // If there are subItems, render a dropdown
  if (subItems && subItems.length > 0) {
    return (
      <Box onMouseEnter={handleOpenDropdown} onMouseLeave={handleCloseDropdown}>
        <StyledNavItem
          component="div"
          to={path}
          sx={{
            '&.active': {
              color: 'text.primary',
              bgcolor: 'action.selected',
              fontWeight: 'fontWeightBold',
            },
          }}
        >
          <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
          <ListItemText disableTypography primary={title} />
          {info && info}
          {openDropdown ? (
      <img src={expand_more} alt="expand" />
    ) : (
      <img src={chevron_left} alt="chevron" />
    )}
        </StyledNavItem>
        {openDropdown && (
          <List disablePadding >
            {subItems.map((subItem) => (
              <NavItem key={subItem.title} item={subItem} />
            ))}
          </List>
        )}
      </Box>
    );
  }

  // If no subItems, render a regular NavItem
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: 'text.primary',
          bgcolor: 'action.selected',
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </StyledNavItem>
  );
}


// function NavItem({ item }) {
//   const { title, path, icon, info, subItems } = item;
//   const [openDropdown, setOpenDropdown] = useState(false);

//   const handleOpenDropdown = () => {
//     setOpenDropdown(true);
//   };

//   const handleCloseDropdown = () => {
//     setOpenDropdown(false);
//   };

//   // If there are subItems, render a dropdown
//   if (subItems && subItems.length > 0) {
//     return (
//       <Box onMouseEnter={handleOpenDropdown} onMouseLeave={handleCloseDropdown}>
//         <StyledNavItem
//           component="div"
//           to={path}
//           sx={{
//             '&.active': {
//               color: 'text.primary',
//               bgcolor: 'action.selected',
//               fontWeight: 'fontWeightBold',
//             },
//           }}
//         >
//           <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
//           <ListItemText disableTypography primary={title} />
//           {info && info}
//         </StyledNavItem>
//         {openDropdown && (
//           <List disablePadding>
//             {subItems.map((subItem) => (
//               <NavItem key={subItem.title} item={subItem} />
//             ))}
//           </List>
//         )}
//       </Box>
//     );
//   }

//   // If no subItems, render a regular NavItem
//   return (
//     <StyledNavItem
//       component={RouterLink}
//       to={path}
//       sx={{
//         '&.active': {
//           color: 'text.primary',
//           bgcolor: 'action.selected',
//           fontWeight: 'fontWeightBold',
//         },
//       }}
//     >
//       <StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>
//       <ListItemText disableTypography primary={title} />
//       {info && info}
//     </StyledNavItem>
//   );
// }
